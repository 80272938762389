const state = () => ({
  snackbar: {
    value: false,
    timeout: 5000,
    top: true,
    centered: true,
    color: '#4E5A65',
    message: '',
  },
  pageLoader: false,
  stockInformation: '',
  popupBannerDialog: true,
})

export default state
