import { baseRes } from './response'
const url = `${process.env.API_BASE_URL}`
export default (axios) => ({
  getSinglePage(
    payload = {
      page: 1,
      size: 100,
      type: '',
    },
    errCallback = null
  ) {
    return baseRes(
      axios.get(
        `${url}/sustainability-single-page/${payload.type}/v1?page=${payload.page}&size=${payload.size}`
      ),
      errCallback
    )
  },
})
