const actions = {
  showSnackbar({ commit }, data) {
    commit('SHOW_SNACKBAR', data)
    if (data.closeButton) {
      // No timeout
    } else {
      setTimeout(
        () => commit('SHOW_SNACKBAR', { value: false }),
        data.timeout || 5000
      )
    }
  },
  closeSnackbar({ commit }) {
    commit('SHOW_SNACKBAR', { value: false })
  },
  setPageLoader({ commit }, isShow) {
    commit('SET_PAGE_LOADER', isShow)
  },
}

export default actions
