import { baseRes } from './response'
const url = `${process.env.API_BASE_URL}/web-setting`
export default (axios) => ({
  getItemKey(
    payload = {
      itemKey: '',
    },
    errCallback = null
  ) {
    return baseRes(axios.get(`${url}/v1/${payload.itemKey}`), errCallback)
  },
})
