import { baseRes } from './response'
const url = `${process.env.API_BASE_URL}`

export default (axios) => ({
  getAPI(errCallback = null) {
    return baseRes(axios.get(`${url}/endpoint`), errCallback)
  },
  getAPIExample(errCallback) {
    return baseRes(axios.get(`${url}/endpoint`), errCallback)
  },
})
