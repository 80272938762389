
export default {
  data: () => ({
    shortDescData: [],
    copyrightData: [],
    addressData: [],
    phoneNumberData: [],
    emailData: [],
    facebookData: [],
    linkedinData: [],
    youtubeData: [],
    instagramData: [],
    isLoading: true,
    footerItemKeys: [
      'footer.shortDesc',
      'footer.copyright',
      'footer.address',
      'footer.phoneNumber',
      'footer.email',
      'footer.facebook',
      'footer.linkedin',
      'footer.youtube',
      'footer.instagram',
    ],
  }),
  async created() {
    this.isLoading = true
    await this.fetchData()
    this.isLoading = false
  },
  methods: {
    async fetchData() {
      this.isLoading = true

      const responses = await Promise.all(
        this.footerItemKeys.map(async (itemKey) => {
          const response = await this.$services.webSetting.getItemKey({
            itemKey,
          })
          return response.success ? response.data : []
        })
      )

      this.shortDescData = responses[0]
      this.copyrightData = responses[1]
      this.addressData = responses[2]
      this.phoneNumberData = responses[3]
      this.emailData = responses[4]
      this.facebookData = responses[5]
      this.linkedinData = responses[6]
      this.youtubeData = responses[7]
      this.instagramData = responses[8]

      this.isLoading = false
    },
  },
}
