
export default {
  name: 'DefaultLayout',
  computed: {
    snackbar() {
      return this.$store.getters['general/getSnackbar']
    },
    isShowPageLoader() {
      return this.$store.getters['general/getPageLoader']
    },
  },
  methods: {
    closeSnackbar() {
      this.$store.dispatch('general/closeSnackbar')
    },
  },
}
