import { baseRes } from './response'
const url = process.env.API_BASE_URL
export default (axios) => ({
  getAnnualReport(
    payload = {
      startYear: '',
      endYear: '',
    },
    errCallback = null
  ) {
    return baseRes(
      axios.get(
        `${url}/report-annual/v1?startYear=${payload.startYear}&endYear=${payload.endYear}`
      ),
      errCallback
    )
  },
  getFinancialReport(
    payload = {
      startYear: '',
      endYear: '',
    },
    errCallback = null
  ) {
    return baseRes(
      axios.get(
        `${url}/report-financial/v1?startYear=${payload.startYear}&endYear=${payload.endYear}`
      ),
      errCallback
    )
  },
  getIdxAnnouncement(
    payload = {
      startYear: '',
      endYear: '',
    },
    errCallback = null
  ) {
    return baseRes(
      axios.get(
        `${url}/idx-announcement/v1?startYear=${payload.startYear}&endYear=${payload.endYear}`
      ),
      errCallback
    )
  },
  getProspectus(
    payload = {
      startYear: '',
      endYear: '',
    },
    errCallback = null
  ) {
    return baseRes(
      axios.get(
        `${url}/prospectus/v1?startYear=${payload.startYear}&endYear=${payload.endYear}`
      ),
      errCallback
    )
  },
  getInvestorPresentation(
    payload = {
      startYear: '',
      endYear: '',
    },
    errCallback = null
  ) {
    return baseRes(
      axios.get(
        `${url}/investor-presentation/v1?startYear=${payload.startYear}&endYear=${payload.endYear}`
      ),
      errCallback
    )
  },
  getRups(
    payload = {
      startYear: '',
      endYear: '',
    },
    errCallback = null
  ) {
    return baseRes(
      axios.get(
        `${url}/rups/v1?startYear=${payload.startYear}&endYear=${payload.endYear}`
      ),
      errCallback
    )
  },
  getPerformance(
    payload = {
      page: 1,
      size: 100,
    },
    errCallback = null
  ) {
    return baseRes(
      axios.get(
        `${url}/investor-performance/v1?page=${payload.page}&size=${payload.size}`
      ),
      errCallback
    )
  },
})
