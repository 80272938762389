import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=7d857f52&scoped=true&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"
import style0 from "./Footer.vue?vue&type=style&index=0&id=7d857f52&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d857f52",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Phone: require('/usr/src/app/src/components/icons/Phone.vue').default,Fax: require('/usr/src/app/src/components/icons/Fax.vue').default,Email: require('/usr/src/app/src/components/icons/Email.vue').default,Facebook: require('/usr/src/app/src/components/icons/Facebook.vue').default,LinkedIn: require('/usr/src/app/src/components/icons/LinkedIn.vue').default,Youtube: require('/usr/src/app/src/components/icons/Youtube.vue').default,Instagram: require('/usr/src/app/src/components/icons/Instagram.vue').default})
