import { baseRes } from './response'
const url = `${process.env.API_BASE_URL}/career`
export default (axios) => ({
  getAllCareer(
    payload = {
      page: 1,
      size: 10,
    },
    errCallback = null
  ) {
    return baseRes(
      axios.get(`${url}/v1?page=${payload.page}&size=${payload.size}`),
      errCallback
    )
  },
})
