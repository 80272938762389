const getters = {
  getSnackbar(state) {
    return state.snackbar
  },
  getPageLoader(state) {
    return state.pageLoader
  },
  getStockInformation(state) {
    return state.stockInformation
  },
  getPopupBannerDialog(state) {
    return state.popupBannerDialog
  },
}

export default getters
