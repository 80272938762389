import Vue from 'vue'
import VueGtag, { pageview } from 'vue-gtag'

export default function (context, inject) {
  Vue.use(VueGtag, {
    config: {
      id: 'G-7NG1VZ6G3N',
    },
    enable: process.env.NODE_ENV === 'production',
  })

  const router = context.app.router

  router.afterEach((to) => {
    Vue.nextTick(() => {
      if (process.env.NODE_ENV === 'production') {
        pageview(to.fullPath)
      }
    })
  })
}
