import { baseRes } from './response'
const url = `${process.env.API_BASE_URL}/news`
export default (axios) => ({
  getAllNews(
    payload = {
      page: 1,
      size: 10,
    },
    errCallback = null
  ) {
    return baseRes(
      axios.get(`${url}/v1?page=${payload.page}&size=${payload.size}`),
      errCallback
    )
  },
  getNewsDetail(
    payload = {
      slug: '',
    },
    errCallback = null
  ) {
    return baseRes(axios.get(`${url}/v1/slug?key=${payload.slug}`), errCallback)
  },
  getNewsFeatured(
    payload = {
      size: '',
    },
    errCallback = null
  ) {
    return baseRes(
      axios.get(`${url}/v1/featured?size${payload.size}`),
      errCallback
    )
  },
})
