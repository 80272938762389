const mutations = {
  SHOW_SNACKBAR(state, data) {
    state.snackbar = {
      value: true,
      timeout: data.closeButton ? 360000 : 5000,
      top: true,
      centered: true,
      color: '#4E5A65',
      message: '',
      ...data,
    }
  },
  SET_PAGE_LOADER(state, isShow) {
    state.pageLoader = isShow
  },
  SET_STOCK_INFORMATION(state, data) {
    state.stockInformation = data
  },
  SET_POPUP_BANNER_DIALOG(state, data) {
    state.popupBannerDialog = data
  },
}

export default mutations
