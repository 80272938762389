import Master from '@/services/master'
import Reports from '@/services/reports'
import News from '@/services/news'
import Contact from '@/services/contact'
import Career from '@/services/career'
import StockInformation from '@/services/stockInformation'
import Governance from '@/services/governance'
import Company from '@/services/company'
import Sustainability from '@/services/sustainability'
import WebSetting from '@/services/webSetting'

export default ({ $axios }, inject) => {
  $axios.create({
    headers: {
      'Content-Type': 'application/json',
    },
  })
  // Initialize API factories
  const factories = {
    master: Master($axios),
    reports: Reports($axios),
    news: News($axios),
    contact: Contact($axios),
    career: Career($axios),
    stockInformation: StockInformation($axios),
    governance: Governance($axios),
    company: Company($axios),
    sustainability: Sustainability($axios),
    webSetting: WebSetting($axios),
  }

  // Inject $services
  inject('services', factories)
}
