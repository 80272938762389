export const Fleet1 = () => import('../../src/components/fleet/Fleet1.vue' /* webpackChunkName: "components/fleet1" */).then(c => wrapFunctional(c.default || c))
export const FleetSlug1 = () => import('../../src/components/fleet/FleetSlug1.vue' /* webpackChunkName: "components/fleet-slug1" */).then(c => wrapFunctional(c.default || c))
export const CurrencyDown = () => import('../../src/components/icons/CurrencyDown.vue' /* webpackChunkName: "components/currency-down" */).then(c => wrapFunctional(c.default || c))
export const CurrencyNormal = () => import('../../src/components/icons/CurrencyNormal.vue' /* webpackChunkName: "components/currency-normal" */).then(c => wrapFunctional(c.default || c))
export const CurrencyUp = () => import('../../src/components/icons/CurrencyUp.vue' /* webpackChunkName: "components/currency-up" */).then(c => wrapFunctional(c.default || c))
export const DownArrowBlue = () => import('../../src/components/icons/DownArrowBlue.vue' /* webpackChunkName: "components/down-arrow-blue" */).then(c => wrapFunctional(c.default || c))
export const DownArrowWhite = () => import('../../src/components/icons/DownArrowWhite.vue' /* webpackChunkName: "components/down-arrow-white" */).then(c => wrapFunctional(c.default || c))
export const Email = () => import('../../src/components/icons/Email.vue' /* webpackChunkName: "components/email" */).then(c => wrapFunctional(c.default || c))
export const Facebook = () => import('../../src/components/icons/Facebook.vue' /* webpackChunkName: "components/facebook" */).then(c => wrapFunctional(c.default || c))
export const Fax = () => import('../../src/components/icons/Fax.vue' /* webpackChunkName: "components/fax" */).then(c => wrapFunctional(c.default || c))
export const Instagram = () => import('../../src/components/icons/Instagram.vue' /* webpackChunkName: "components/instagram" */).then(c => wrapFunctional(c.default || c))
export const LeftArrowGray = () => import('../../src/components/icons/LeftArrowGray.vue' /* webpackChunkName: "components/left-arrow-gray" */).then(c => wrapFunctional(c.default || c))
export const LinkedIn = () => import('../../src/components/icons/LinkedIn.vue' /* webpackChunkName: "components/linked-in" */).then(c => wrapFunctional(c.default || c))
export const Phone = () => import('../../src/components/icons/Phone.vue' /* webpackChunkName: "components/phone" */).then(c => wrapFunctional(c.default || c))
export const RightArrowBlue = () => import('../../src/components/icons/RightArrowBlue.vue' /* webpackChunkName: "components/right-arrow-blue" */).then(c => wrapFunctional(c.default || c))
export const RightArrowGray = () => import('../../src/components/icons/RightArrowGray.vue' /* webpackChunkName: "components/right-arrow-gray" */).then(c => wrapFunctional(c.default || c))
export const Twitter = () => import('../../src/components/icons/Twitter.vue' /* webpackChunkName: "components/twitter" */).then(c => wrapFunctional(c.default || c))
export const UpArrowBlue = () => import('../../src/components/icons/UpArrowBlue.vue' /* webpackChunkName: "components/up-arrow-blue" */).then(c => wrapFunctional(c.default || c))
export const UpArrowWhite = () => import('../../src/components/icons/UpArrowWhite.vue' /* webpackChunkName: "components/up-arrow-white" */).then(c => wrapFunctional(c.default || c))
export const Youtube = () => import('../../src/components/icons/Youtube.vue' /* webpackChunkName: "components/youtube" */).then(c => wrapFunctional(c.default || c))
export const BackgroundCareerAndContact = () => import('../../src/components/background/containers/BackgroundCareerAndContact.vue' /* webpackChunkName: "components/background-career-and-contact" */).then(c => wrapFunctional(c.default || c))
export const BackgroundCompany1 = () => import('../../src/components/background/containers/BackgroundCompany1.vue' /* webpackChunkName: "components/background-company1" */).then(c => wrapFunctional(c.default || c))
export const BackgroundDefault = () => import('../../src/components/background/containers/BackgroundDefault.vue' /* webpackChunkName: "components/background-default" */).then(c => wrapFunctional(c.default || c))
export const BackgroundGovernance1 = () => import('../../src/components/background/containers/BackgroundGovernance1.vue' /* webpackChunkName: "components/background-governance1" */).then(c => wrapFunctional(c.default || c))
export const BackgroundInvestors = () => import('../../src/components/background/containers/BackgroundInvestors.vue' /* webpackChunkName: "components/background-investors" */).then(c => wrapFunctional(c.default || c))
export const BackgroundNews = () => import('../../src/components/background/containers/BackgroundNews.vue' /* webpackChunkName: "components/background-news" */).then(c => wrapFunctional(c.default || c))
export const BackgroundServices = () => import('../../src/components/background/containers/BackgroundServices.vue' /* webpackChunkName: "components/background-services" */).then(c => wrapFunctional(c.default || c))
export const BackgroundSustainability = () => import('../../src/components/background/containers/BackgroundSustainability.vue' /* webpackChunkName: "components/background-sustainability" */).then(c => wrapFunctional(c.default || c))
export const DateInput = () => import('../../src/components/base/components/DateInput.vue' /* webpackChunkName: "components/date-input" */).then(c => wrapFunctional(c.default || c))
export const EmptyState = () => import('../../src/components/base/components/EmptyState.vue' /* webpackChunkName: "components/empty-state" */).then(c => wrapFunctional(c.default || c))
export const FileCard = () => import('../../src/components/base/components/FileCard.vue' /* webpackChunkName: "components/file-card" */).then(c => wrapFunctional(c.default || c))
export const ListItem = () => import('../../src/components/base/components/ListItem.vue' /* webpackChunkName: "components/list-item" */).then(c => wrapFunctional(c.default || c))
export const ComingSoon = () => import('../../src/components/base/containers/ComingSoon.vue' /* webpackChunkName: "components/coming-soon" */).then(c => wrapFunctional(c.default || c))
export const ListGroup = () => import('../../src/components/base/containers/ListGroup.vue' /* webpackChunkName: "components/list-group" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../src/components/base/containers/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const PopupBanner = () => import('../../src/components/base/containers/PopupBanner.vue' /* webpackChunkName: "components/popup-banner" */).then(c => wrapFunctional(c.default || c))
export const SnackbarInfo = () => import('../../src/components/base/containers/SnackbarInfo.vue' /* webpackChunkName: "components/snackbar-info" */).then(c => wrapFunctional(c.default || c))
export const Spinner = () => import('../../src/components/base/containers/Spinner.vue' /* webpackChunkName: "components/spinner" */).then(c => wrapFunctional(c.default || c))
export const CareerDetailModal = () => import('../../src/components/career/components/CareerDetailModal.vue' /* webpackChunkName: "components/career-detail-modal" */).then(c => wrapFunctional(c.default || c))
export const CareerItems = () => import('../../src/components/career/components/CareerItems.vue' /* webpackChunkName: "components/career-items" */).then(c => wrapFunctional(c.default || c))
export const CareerList = () => import('../../src/components/career/containers/CareerList.vue' /* webpackChunkName: "components/career-list" */).then(c => wrapFunctional(c.default || c))
export const Location = () => import('../../src/components/career/icons/Location.vue' /* webpackChunkName: "components/location" */).then(c => wrapFunctional(c.default || c))
export const Worktype = () => import('../../src/components/career/icons/Worktype.vue' /* webpackChunkName: "components/worktype" */).then(c => wrapFunctional(c.default || c))
export const AchievementTable = () => import('../../src/components/company/components/AchievementTable.vue' /* webpackChunkName: "components/achievement-table" */).then(c => wrapFunctional(c.default || c))
export const BocBodDetailModal = () => import('../../src/components/company/components/BocBodDetailModal.vue' /* webpackChunkName: "components/boc-bod-detail-modal" */).then(c => wrapFunctional(c.default || c))
export const CorporateValueModal = () => import('../../src/components/company/components/CorporateValueModal.vue' /* webpackChunkName: "components/corporate-value-modal" */).then(c => wrapFunctional(c.default || c))
export const RecognitionPartners = () => import('../../src/components/company/components/RecognitionPartners.vue' /* webpackChunkName: "components/recognition-partners" */).then(c => wrapFunctional(c.default || c))
export const VisiMisiDetail = () => import('../../src/components/company/components/VisiMisiDetail.vue' /* webpackChunkName: "components/visi-misi-detail" */).then(c => wrapFunctional(c.default || c))
export const DirectorCard = () => import('../../src/components/company/containers/DirectorCard.vue' /* webpackChunkName: "components/director-card" */).then(c => wrapFunctional(c.default || c))
export const HeaderContent = () => import('../../src/components/content/components/HeaderContent.vue' /* webpackChunkName: "components/header-content" */).then(c => wrapFunctional(c.default || c))
export const Content = () => import('../../src/components/content/containers/Content.vue' /* webpackChunkName: "components/content" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../src/components/content/containers/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const PracticeHealthSafety = () => import('../../src/components/csr/containers/PracticeHealthSafety.vue' /* webpackChunkName: "components/practice-health-safety" */).then(c => wrapFunctional(c.default || c))
export const ProductServiceResponsibilities = () => import('../../src/components/csr/containers/ProductServiceResponsibilities.vue' /* webpackChunkName: "components/product-service-responsibilities" */).then(c => wrapFunctional(c.default || c))
export const SocialCommunityDevelopment = () => import('../../src/components/csr/containers/SocialCommunityDevelopment.vue' /* webpackChunkName: "components/social-community-development" */).then(c => wrapFunctional(c.default || c))
export const Currency = () => import('../../src/components/currency/containers/Currency.vue' /* webpackChunkName: "components/currency" */).then(c => wrapFunctional(c.default || c))
export const FooterItem = () => import('../../src/components/footer/components/FooterItem.vue' /* webpackChunkName: "components/footer-item" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../src/components/footer/containers/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Footer2 = () => import('../../src/components/footer/containers/Footer2.vue' /* webpackChunkName: "components/footer2" */).then(c => wrapFunctional(c.default || c))
export const AuditCommittee1 = () => import('../../src/components/governance/containers/AuditCommittee1.vue' /* webpackChunkName: "components/audit-committee1" */).then(c => wrapFunctional(c.default || c))
export const AuditCommitteeComp = () => import('../../src/components/governance/containers/AuditCommitteeComp.vue' /* webpackChunkName: "components/audit-committee-comp" */).then(c => wrapFunctional(c.default || c))
export const HomeHeader = () => import('../../src/components/home/components/HomeHeader.vue' /* webpackChunkName: "components/home-header" */).then(c => wrapFunctional(c.default || c))
export const HomeNewsCard = () => import('../../src/components/home/components/HomeNewsCard.vue' /* webpackChunkName: "components/home-news-card" */).then(c => wrapFunctional(c.default || c))
export const HomeNewsListDesktop = () => import('../../src/components/home/components/HomeNewsListDesktop.vue' /* webpackChunkName: "components/home-news-list-desktop" */).then(c => wrapFunctional(c.default || c))
export const HomeNewsListMobile = () => import('../../src/components/home/components/HomeNewsListMobile.vue' /* webpackChunkName: "components/home-news-list-mobile" */).then(c => wrapFunctional(c.default || c))
export const HomeServicesCard = () => import('../../src/components/home/components/HomeServicesCard.vue' /* webpackChunkName: "components/home-services-card" */).then(c => wrapFunctional(c.default || c))
export const HomeFeature = () => import('../../src/components/home/containers/HomeFeature.vue' /* webpackChunkName: "components/home-feature" */).then(c => wrapFunctional(c.default || c))
export const HomeJumbotron = () => import('../../src/components/home/containers/HomeJumbotron.vue' /* webpackChunkName: "components/home-jumbotron" */).then(c => wrapFunctional(c.default || c))
export const HomeMenu = () => import('../../src/components/home/containers/HomeMenu.vue' /* webpackChunkName: "components/home-menu" */).then(c => wrapFunctional(c.default || c))
export const HomeNews = () => import('../../src/components/home/containers/HomeNews.vue' /* webpackChunkName: "components/home-news" */).then(c => wrapFunctional(c.default || c))
export const HomeServices = () => import('../../src/components/home/containers/HomeServices.vue' /* webpackChunkName: "components/home-services" */).then(c => wrapFunctional(c.default || c))
export const RightArrowBlueHomeSection = () => import('../../src/components/home/icons/RightArrowBlueHomeSection.vue' /* webpackChunkName: "components/right-arrow-blue-home-section" */).then(c => wrapFunctional(c.default || c))
export const InvestorsItems = () => import('../../src/components/investors/components/InvestorsItems.vue' /* webpackChunkName: "components/investors-items" */).then(c => wrapFunctional(c.default || c))
export const Navbar = () => import('../../src/components/navbar/containers/Navbar.vue' /* webpackChunkName: "components/navbar" */).then(c => wrapFunctional(c.default || c))
export const NewsListCarouselDesktop = () => import('../../src/components/news/components/NewsListCarouselDesktop.vue' /* webpackChunkName: "components/news-list-carousel-desktop" */).then(c => wrapFunctional(c.default || c))
export const NewsListCarouselMobile = () => import('../../src/components/news/components/NewsListCarouselMobile.vue' /* webpackChunkName: "components/news-list-carousel-mobile" */).then(c => wrapFunctional(c.default || c))
export const NewsItems = () => import('../../src/components/news/containers/NewsItems.vue' /* webpackChunkName: "components/news-items" */).then(c => wrapFunctional(c.default || c))
export const ServicesSection2 = () => import('../../src/components/services/containers/ServicesSection2.vue' /* webpackChunkName: "components/services-section2" */).then(c => wrapFunctional(c.default || c))
export const ServicesSection3 = () => import('../../src/components/services/containers/ServicesSection3.vue' /* webpackChunkName: "components/services-section3" */).then(c => wrapFunctional(c.default || c))
export const ServicesSection4 = () => import('../../src/components/services/containers/ServicesSection4.vue' /* webpackChunkName: "components/services-section4" */).then(c => wrapFunctional(c.default || c))
export const ServicesSection5 = () => import('../../src/components/services/containers/ServicesSection5.vue' /* webpackChunkName: "components/services-section5" */).then(c => wrapFunctional(c.default || c))
export const ServicesSection6 = () => import('../../src/components/services/containers/ServicesSection6.vue' /* webpackChunkName: "components/services-section6" */).then(c => wrapFunctional(c.default || c))
export const TableDownload = () => import('../../src/components/table/containers/TableDownload.vue' /* webpackChunkName: "components/table-download" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
