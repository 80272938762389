import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1f1f5c74 = () => interopDefault(import('../src/pages/basicContent.vue' /* webpackChunkName: "pages/basicContent" */))
const _1f152548 = () => interopDefault(import('../src/pages/career/index.vue' /* webpackChunkName: "pages/career/index" */))
const _8736b150 = () => interopDefault(import('../src/pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _569f7696 = () => interopDefault(import('../src/pages/docs.vue' /* webpackChunkName: "pages/docs" */))
const _1bb8d387 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _8fd4ddda = () => interopDefault(import('../src/pages/investors/index.vue' /* webpackChunkName: "pages/investors/index" */))
const _1e62819e = () => interopDefault(import('../src/pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _3b35f1b2 = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _573e4d08 = () => interopDefault(import('../src/pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _1ae3932f = () => interopDefault(import('../src/pages/company/article-of-association.vue' /* webpackChunkName: "pages/company/article-of-association" */))
const _293e6219 = () => interopDefault(import('../src/pages/company/board-of-commissioners.vue' /* webpackChunkName: "pages/company/board-of-commissioners" */))
const _564f7ce5 = () => interopDefault(import('../src/pages/company/board-of-directors.vue' /* webpackChunkName: "pages/company/board-of-directors" */))
const _dca85c74 = () => interopDefault(import('../src/pages/company/company-structure.vue' /* webpackChunkName: "pages/company/company-structure" */))
const _7006ab76 = () => interopDefault(import('../src/pages/company/institution-and-capital-market-supporting-professionals.vue' /* webpackChunkName: "pages/company/institution-and-capital-market-supporting-professionals" */))
const _1c08b8da = () => interopDefault(import('../src/pages/company/milestone.vue' /* webpackChunkName: "pages/company/milestone" */))
const _393081c6 = () => interopDefault(import('../src/pages/company/organization-structure.vue' /* webpackChunkName: "pages/company/organization-structure" */))
const _59198ea6 = () => interopDefault(import('../src/pages/company/overview.vue' /* webpackChunkName: "pages/company/overview" */))
const _0dcc0732 = () => interopDefault(import('../src/pages/company/partnership.vue' /* webpackChunkName: "pages/company/partnership" */))
const _08f6d79a = () => interopDefault(import('../src/pages/company/recognition.vue' /* webpackChunkName: "pages/company/recognition" */))
const _1d36dcd8 = () => interopDefault(import('../src/pages/company/shareholder-structure.vue' /* webpackChunkName: "pages/company/shareholder-structure" */))
const _9b145dd8 = () => interopDefault(import('../src/pages/governance/anti-corruption.vue' /* webpackChunkName: "pages/governance/anti-corruption" */))
const _b53df70e = () => interopDefault(import('../src/pages/governance/audit-committee.vue' /* webpackChunkName: "pages/governance/audit-committee" */))
const _6902d696 = () => interopDefault(import('../src/pages/governance/boc-work-guidelines.vue' /* webpackChunkName: "pages/governance/boc-work-guidelines" */))
const _759bcc17 = () => interopDefault(import('../src/pages/governance/bod-work-guidelines.vue' /* webpackChunkName: "pages/governance/bod-work-guidelines" */))
const _216bb3c8 = () => interopDefault(import('../src/pages/governance/code-of-conduct.vue' /* webpackChunkName: "pages/governance/code-of-conduct" */))
const _b5aa0584 = () => interopDefault(import('../src/pages/governance/corporate-secretary.vue' /* webpackChunkName: "pages/governance/corporate-secretary" */))
const _61b688c8 = () => interopDefault(import('../src/pages/governance/good-corporate-governance.vue' /* webpackChunkName: "pages/governance/good-corporate-governance" */))
const _56c517cd = () => interopDefault(import('../src/pages/governance/internal-audit.vue' /* webpackChunkName: "pages/governance/internal-audit" */))
const _ee547a88 = () => interopDefault(import('../src/pages/governance/nomination-remuneration-committee.vue' /* webpackChunkName: "pages/governance/nomination-remuneration-committee" */))
const _54d96b6f = () => interopDefault(import('../src/pages/governance/risk-management.vue' /* webpackChunkName: "pages/governance/risk-management" */))
const _13385428 = () => interopDefault(import('../src/pages/governance/whistleblowing-system.vue' /* webpackChunkName: "pages/governance/whistleblowing-system" */))
const _0aa227ba = () => interopDefault(import('../src/pages/investors/annual-report.vue' /* webpackChunkName: "pages/investors/annual-report" */))
const _6bb3f71e = () => interopDefault(import('../src/pages/investors/bond-information.vue' /* webpackChunkName: "pages/investors/bond-information" */))
const _947c7426 = () => interopDefault(import('../src/pages/investors/corporate-action.vue' /* webpackChunkName: "pages/investors/corporate-action" */))
const _458de416 = () => interopDefault(import('../src/pages/investors/dividen-information.vue' /* webpackChunkName: "pages/investors/dividen-information" */))
const _3ecddb47 = () => interopDefault(import('../src/pages/investors/financial-report.vue' /* webpackChunkName: "pages/investors/financial-report" */))
const _e6b9b9b4 = () => interopDefault(import('../src/pages/investors/idx-announcement.vue' /* webpackChunkName: "pages/investors/idx-announcement" */))
const _3d2155f0 = () => interopDefault(import('../src/pages/investors/investor-presentation.vue' /* webpackChunkName: "pages/investors/investor-presentation" */))
const _5e3136b1 = () => interopDefault(import('../src/pages/investors/performance.vue' /* webpackChunkName: "pages/investors/performance" */))
const _6bee2db9 = () => interopDefault(import('../src/pages/investors/prospectus.vue' /* webpackChunkName: "pages/investors/prospectus" */))
const _6e13af55 = () => interopDefault(import('../src/pages/investors/rups.vue' /* webpackChunkName: "pages/investors/rups" */))
const _0fb91394 = () => interopDefault(import('../src/pages/investors/stock-information.vue' /* webpackChunkName: "pages/investors/stock-information" */))
const _9ce4870a = () => interopDefault(import('../src/pages/services/fleet/index.vue' /* webpackChunkName: "pages/services/fleet/index" */))
const _08550c74 = () => interopDefault(import('../src/pages/sustainability/environment.vue' /* webpackChunkName: "pages/sustainability/environment" */))
const _64d44e3e = () => interopDefault(import('../src/pages/sustainability/social-responsibility.vue' /* webpackChunkName: "pages/sustainability/social-responsibility" */))
const _a051c99a = () => interopDefault(import('../src/pages/services/fleet/_slug.vue' /* webpackChunkName: "pages/services/fleet/_slug" */))
const _21cfc42e = () => interopDefault(import('../src/pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/basicContent",
    component: _1f1f5c74,
    name: "basicContent___en___default"
  }, {
    path: "/career",
    component: _1f152548,
    name: "career___en___default"
  }, {
    path: "/contact",
    component: _8736b150,
    name: "contact___en___default"
  }, {
    path: "/docs",
    component: _569f7696,
    name: "docs___en___default"
  }, {
    path: "/en",
    component: _1bb8d387,
    name: "index___en"
  }, {
    path: "/id",
    component: _1bb8d387,
    name: "index___id"
  }, {
    path: "/investors",
    component: _8fd4ddda,
    name: "investors___en___default"
  }, {
    path: "/news",
    component: _1e62819e,
    name: "news___en___default"
  }, {
    path: "/privacy-policy",
    component: _3b35f1b2,
    name: "privacy-policy___en___default"
  }, {
    path: "/services",
    component: _573e4d08,
    name: "services___en___default"
  }, {
    path: "/company/article-of-association",
    component: _1ae3932f,
    name: "company-article-of-association___en___default"
  }, {
    path: "/company/board-of-commissioners",
    component: _293e6219,
    name: "company-board-of-commissioners___en___default"
  }, {
    path: "/company/board-of-directors",
    component: _564f7ce5,
    name: "company-board-of-directors___en___default"
  }, {
    path: "/company/company-structure",
    component: _dca85c74,
    name: "company-company-structure___en___default"
  }, {
    path: "/company/institution-and-capital-market-supporting-professionals",
    component: _7006ab76,
    name: "company-institution-and-capital-market-supporting-professionals___en___default"
  }, {
    path: "/company/milestone",
    component: _1c08b8da,
    name: "company-milestone___en___default"
  }, {
    path: "/company/organization-structure",
    component: _393081c6,
    name: "company-organization-structure___en___default"
  }, {
    path: "/company/overview",
    component: _59198ea6,
    name: "company-overview___en___default"
  }, {
    path: "/company/partnership",
    component: _0dcc0732,
    name: "company-partnership___en___default"
  }, {
    path: "/company/recognition",
    component: _08f6d79a,
    name: "company-recognition___en___default"
  }, {
    path: "/company/shareholder-structure",
    component: _1d36dcd8,
    name: "company-shareholder-structure___en___default"
  }, {
    path: "/en/basicContent",
    component: _1f1f5c74,
    name: "basicContent___en"
  }, {
    path: "/en/career",
    component: _1f152548,
    name: "career___en"
  }, {
    path: "/en/contact",
    component: _8736b150,
    name: "contact___en"
  }, {
    path: "/en/docs",
    component: _569f7696,
    name: "docs___en"
  }, {
    path: "/en/investors",
    component: _8fd4ddda,
    name: "investors___en"
  }, {
    path: "/en/news",
    component: _1e62819e,
    name: "news___en"
  }, {
    path: "/en/privacy-policy",
    component: _3b35f1b2,
    name: "privacy-policy___en"
  }, {
    path: "/en/services",
    component: _573e4d08,
    name: "services___en"
  }, {
    path: "/governance/anti-corruption",
    component: _9b145dd8,
    name: "governance-anti-corruption___en___default"
  }, {
    path: "/governance/audit-committee",
    component: _b53df70e,
    name: "governance-audit-committee___en___default"
  }, {
    path: "/governance/boc-work-guidelines",
    component: _6902d696,
    name: "governance-boc-work-guidelines___en___default"
  }, {
    path: "/governance/bod-work-guidelines",
    component: _759bcc17,
    name: "governance-bod-work-guidelines___en___default"
  }, {
    path: "/governance/code-of-conduct",
    component: _216bb3c8,
    name: "governance-code-of-conduct___en___default"
  }, {
    path: "/governance/corporate-secretary",
    component: _b5aa0584,
    name: "governance-corporate-secretary___en___default"
  }, {
    path: "/governance/good-corporate-governance",
    component: _61b688c8,
    name: "governance-good-corporate-governance___en___default"
  }, {
    path: "/governance/internal-audit",
    component: _56c517cd,
    name: "governance-internal-audit___en___default"
  }, {
    path: "/governance/nomination-remuneration-committee",
    component: _ee547a88,
    name: "governance-nomination-remuneration-committee___en___default"
  }, {
    path: "/governance/risk-management",
    component: _54d96b6f,
    name: "governance-risk-management___en___default"
  }, {
    path: "/governance/whistleblowing-system",
    component: _13385428,
    name: "governance-whistleblowing-system___en___default"
  }, {
    path: "/id/basicContent",
    component: _1f1f5c74,
    name: "basicContent___id"
  }, {
    path: "/id/career",
    component: _1f152548,
    name: "career___id"
  }, {
    path: "/id/contact",
    component: _8736b150,
    name: "contact___id"
  }, {
    path: "/id/docs",
    component: _569f7696,
    name: "docs___id"
  }, {
    path: "/id/investors",
    component: _8fd4ddda,
    name: "investors___id"
  }, {
    path: "/id/news",
    component: _1e62819e,
    name: "news___id"
  }, {
    path: "/id/privacy-policy",
    component: _3b35f1b2,
    name: "privacy-policy___id"
  }, {
    path: "/id/services",
    component: _573e4d08,
    name: "services___id"
  }, {
    path: "/investors/annual-report",
    component: _0aa227ba,
    name: "investors-annual-report___en___default"
  }, {
    path: "/investors/bond-information",
    component: _6bb3f71e,
    name: "investors-bond-information___en___default"
  }, {
    path: "/investors/corporate-action",
    component: _947c7426,
    name: "investors-corporate-action___en___default"
  }, {
    path: "/investors/dividen-information",
    component: _458de416,
    name: "investors-dividen-information___en___default"
  }, {
    path: "/investors/financial-report",
    component: _3ecddb47,
    name: "investors-financial-report___en___default"
  }, {
    path: "/investors/idx-announcement",
    component: _e6b9b9b4,
    name: "investors-idx-announcement___en___default"
  }, {
    path: "/investors/investor-presentation",
    component: _3d2155f0,
    name: "investors-investor-presentation___en___default"
  }, {
    path: "/investors/performance",
    component: _5e3136b1,
    name: "investors-performance___en___default"
  }, {
    path: "/investors/prospectus",
    component: _6bee2db9,
    name: "investors-prospectus___en___default"
  }, {
    path: "/investors/rups",
    component: _6e13af55,
    name: "investors-rups___en___default"
  }, {
    path: "/investors/stock-information",
    component: _0fb91394,
    name: "investors-stock-information___en___default"
  }, {
    path: "/services/fleet",
    component: _9ce4870a,
    name: "services-fleet___en___default"
  }, {
    path: "/sustainability/environment",
    component: _08550c74,
    name: "sustainability-environment___en___default"
  }, {
    path: "/sustainability/social-responsibility",
    component: _64d44e3e,
    name: "sustainability-social-responsibility___en___default"
  }, {
    path: "/en/company/article-of-association",
    component: _1ae3932f,
    name: "company-article-of-association___en"
  }, {
    path: "/en/company/board-of-commissioners",
    component: _293e6219,
    name: "company-board-of-commissioners___en"
  }, {
    path: "/en/company/board-of-directors",
    component: _564f7ce5,
    name: "company-board-of-directors___en"
  }, {
    path: "/en/company/company-structure",
    component: _dca85c74,
    name: "company-company-structure___en"
  }, {
    path: "/en/company/institution-and-capital-market-supporting-professionals",
    component: _7006ab76,
    name: "company-institution-and-capital-market-supporting-professionals___en"
  }, {
    path: "/en/company/milestone",
    component: _1c08b8da,
    name: "company-milestone___en"
  }, {
    path: "/en/company/organization-structure",
    component: _393081c6,
    name: "company-organization-structure___en"
  }, {
    path: "/en/company/overview",
    component: _59198ea6,
    name: "company-overview___en"
  }, {
    path: "/en/company/partnership",
    component: _0dcc0732,
    name: "company-partnership___en"
  }, {
    path: "/en/company/recognition",
    component: _08f6d79a,
    name: "company-recognition___en"
  }, {
    path: "/en/company/shareholder-structure",
    component: _1d36dcd8,
    name: "company-shareholder-structure___en"
  }, {
    path: "/en/governance/anti-corruption",
    component: _9b145dd8,
    name: "governance-anti-corruption___en"
  }, {
    path: "/en/governance/audit-committee",
    component: _b53df70e,
    name: "governance-audit-committee___en"
  }, {
    path: "/en/governance/boc-work-guidelines",
    component: _6902d696,
    name: "governance-boc-work-guidelines___en"
  }, {
    path: "/en/governance/bod-work-guidelines",
    component: _759bcc17,
    name: "governance-bod-work-guidelines___en"
  }, {
    path: "/en/governance/code-of-conduct",
    component: _216bb3c8,
    name: "governance-code-of-conduct___en"
  }, {
    path: "/en/governance/corporate-secretary",
    component: _b5aa0584,
    name: "governance-corporate-secretary___en"
  }, {
    path: "/en/governance/good-corporate-governance",
    component: _61b688c8,
    name: "governance-good-corporate-governance___en"
  }, {
    path: "/en/governance/internal-audit",
    component: _56c517cd,
    name: "governance-internal-audit___en"
  }, {
    path: "/en/governance/nomination-remuneration-committee",
    component: _ee547a88,
    name: "governance-nomination-remuneration-committee___en"
  }, {
    path: "/en/governance/risk-management",
    component: _54d96b6f,
    name: "governance-risk-management___en"
  }, {
    path: "/en/governance/whistleblowing-system",
    component: _13385428,
    name: "governance-whistleblowing-system___en"
  }, {
    path: "/en/investors/annual-report",
    component: _0aa227ba,
    name: "investors-annual-report___en"
  }, {
    path: "/en/investors/bond-information",
    component: _6bb3f71e,
    name: "investors-bond-information___en"
  }, {
    path: "/en/investors/corporate-action",
    component: _947c7426,
    name: "investors-corporate-action___en"
  }, {
    path: "/en/investors/dividen-information",
    component: _458de416,
    name: "investors-dividen-information___en"
  }, {
    path: "/en/investors/financial-report",
    component: _3ecddb47,
    name: "investors-financial-report___en"
  }, {
    path: "/en/investors/idx-announcement",
    component: _e6b9b9b4,
    name: "investors-idx-announcement___en"
  }, {
    path: "/en/investors/investor-presentation",
    component: _3d2155f0,
    name: "investors-investor-presentation___en"
  }, {
    path: "/en/investors/performance",
    component: _5e3136b1,
    name: "investors-performance___en"
  }, {
    path: "/en/investors/prospectus",
    component: _6bee2db9,
    name: "investors-prospectus___en"
  }, {
    path: "/en/investors/rups",
    component: _6e13af55,
    name: "investors-rups___en"
  }, {
    path: "/en/investors/stock-information",
    component: _0fb91394,
    name: "investors-stock-information___en"
  }, {
    path: "/en/services/fleet",
    component: _9ce4870a,
    name: "services-fleet___en"
  }, {
    path: "/en/sustainability/environment",
    component: _08550c74,
    name: "sustainability-environment___en"
  }, {
    path: "/en/sustainability/social-responsibility",
    component: _64d44e3e,
    name: "sustainability-social-responsibility___en"
  }, {
    path: "/id/company/article-of-association",
    component: _1ae3932f,
    name: "company-article-of-association___id"
  }, {
    path: "/id/company/board-of-commissioners",
    component: _293e6219,
    name: "company-board-of-commissioners___id"
  }, {
    path: "/id/company/board-of-directors",
    component: _564f7ce5,
    name: "company-board-of-directors___id"
  }, {
    path: "/id/company/company-structure",
    component: _dca85c74,
    name: "company-company-structure___id"
  }, {
    path: "/id/company/institution-and-capital-market-supporting-professionals",
    component: _7006ab76,
    name: "company-institution-and-capital-market-supporting-professionals___id"
  }, {
    path: "/id/company/milestone",
    component: _1c08b8da,
    name: "company-milestone___id"
  }, {
    path: "/id/company/organization-structure",
    component: _393081c6,
    name: "company-organization-structure___id"
  }, {
    path: "/id/company/overview",
    component: _59198ea6,
    name: "company-overview___id"
  }, {
    path: "/id/company/partnership",
    component: _0dcc0732,
    name: "company-partnership___id"
  }, {
    path: "/id/company/recognition",
    component: _08f6d79a,
    name: "company-recognition___id"
  }, {
    path: "/id/company/shareholder-structure",
    component: _1d36dcd8,
    name: "company-shareholder-structure___id"
  }, {
    path: "/id/governance/anti-corruption",
    component: _9b145dd8,
    name: "governance-anti-corruption___id"
  }, {
    path: "/id/governance/audit-committee",
    component: _b53df70e,
    name: "governance-audit-committee___id"
  }, {
    path: "/id/governance/boc-work-guidelines",
    component: _6902d696,
    name: "governance-boc-work-guidelines___id"
  }, {
    path: "/id/governance/bod-work-guidelines",
    component: _759bcc17,
    name: "governance-bod-work-guidelines___id"
  }, {
    path: "/id/governance/code-of-conduct",
    component: _216bb3c8,
    name: "governance-code-of-conduct___id"
  }, {
    path: "/id/governance/corporate-secretary",
    component: _b5aa0584,
    name: "governance-corporate-secretary___id"
  }, {
    path: "/id/governance/good-corporate-governance",
    component: _61b688c8,
    name: "governance-good-corporate-governance___id"
  }, {
    path: "/id/governance/internal-audit",
    component: _56c517cd,
    name: "governance-internal-audit___id"
  }, {
    path: "/id/governance/nomination-remuneration-committee",
    component: _ee547a88,
    name: "governance-nomination-remuneration-committee___id"
  }, {
    path: "/id/governance/risk-management",
    component: _54d96b6f,
    name: "governance-risk-management___id"
  }, {
    path: "/id/governance/whistleblowing-system",
    component: _13385428,
    name: "governance-whistleblowing-system___id"
  }, {
    path: "/id/investors/annual-report",
    component: _0aa227ba,
    name: "investors-annual-report___id"
  }, {
    path: "/id/investors/bond-information",
    component: _6bb3f71e,
    name: "investors-bond-information___id"
  }, {
    path: "/id/investors/corporate-action",
    component: _947c7426,
    name: "investors-corporate-action___id"
  }, {
    path: "/id/investors/dividen-information",
    component: _458de416,
    name: "investors-dividen-information___id"
  }, {
    path: "/id/investors/financial-report",
    component: _3ecddb47,
    name: "investors-financial-report___id"
  }, {
    path: "/id/investors/idx-announcement",
    component: _e6b9b9b4,
    name: "investors-idx-announcement___id"
  }, {
    path: "/id/investors/investor-presentation",
    component: _3d2155f0,
    name: "investors-investor-presentation___id"
  }, {
    path: "/id/investors/performance",
    component: _5e3136b1,
    name: "investors-performance___id"
  }, {
    path: "/id/investors/prospectus",
    component: _6bee2db9,
    name: "investors-prospectus___id"
  }, {
    path: "/id/investors/rups",
    component: _6e13af55,
    name: "investors-rups___id"
  }, {
    path: "/id/investors/stock-information",
    component: _0fb91394,
    name: "investors-stock-information___id"
  }, {
    path: "/id/services/fleet",
    component: _9ce4870a,
    name: "services-fleet___id"
  }, {
    path: "/id/sustainability/environment",
    component: _08550c74,
    name: "sustainability-environment___id"
  }, {
    path: "/id/sustainability/social-responsibility",
    component: _64d44e3e,
    name: "sustainability-social-responsibility___id"
  }, {
    path: "/",
    component: _1bb8d387,
    name: "index___en___default"
  }, {
    path: "/en/services/fleet/:slug",
    component: _a051c99a,
    name: "services-fleet-slug___en"
  }, {
    path: "/id/services/fleet/:slug",
    component: _a051c99a,
    name: "services-fleet-slug___id"
  }, {
    path: "/en/news/:slug",
    component: _21cfc42e,
    name: "news-slug___en"
  }, {
    path: "/id/news/:slug",
    component: _21cfc42e,
    name: "news-slug___id"
  }, {
    path: "/services/fleet/:slug",
    component: _a051c99a,
    name: "services-fleet-slug___en___default"
  }, {
    path: "/news/:slug",
    component: _21cfc42e,
    name: "news-slug___en___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
