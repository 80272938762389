
import { mdiMenu } from '@mdi/js'
export default {
  setup() {
    return {
      mdiMenu,
    }
  },
  data: () => ({
    headerLogoData: [],
    isLoading: true,
    itemKey: 'header.logo',
    drawer: false,
    scrollPosition: null,
    companyHover: false,
    servicesHover: false,
    investorsHover: false,
    governanceHover: false,
    sustainabilityHover: false,
  }),
  computed: {
    itemsCompany() {
      return [
        {
          id: 0,
          title: `${this.$t('navbar.company.overview')}`,
          url: '/company/overview',
        },
        {
          id: 1,
          title: `${this.$t('navbar.company.boc')}`,
          url: '/company/board-of-commissioners',
        },
        {
          id: 2,
          title: `${this.$t('navbar.company.bod')}`,
          url: '/company/board-of-directors',
        },
        {
          id: 3,
          title: `${this.$t('navbar.company.organizationStructure')}`,
          url: '/company/organization-structure',
        },
        {
          id: 4,
          title: `${this.$t('navbar.company.shareholderStructure')}`,
          url: '/company/shareholder-structure',
        },
        {
          id: 5,
          title: `${this.$t('navbar.company.chartOfCompanyStructure')}`,
          url: '/company/company-structure',
        },
        {
          id: 6,
          title: `${this.$t('navbar.company.milestone')}`,
          url: '/company/milestone',
        },
        {
          id: 7,
          title: `${this.$t('navbar.company.recognition')}`,
          url: '/company/recognition',
        },
        {
          id: 8,
          title: `${this.$t('navbar.company.articleOfAssociation')}`,
          url: '/company/article-of-association',
        },
        // {
        //   id: 11,
        //   title: `${this.$t('navbar.company.partnership')}`,
        //   url: '/company/partnership',
        // },
        {
          id: 9,
          title: `${this.$t('navbar.company.newsUpdate')}`,
          url: '/news',
        },
      ]
    },
    itemsGovernance() {
      return [
        {
          id: 0,
          title: `${this.$t('navbar.governance.cocBoc')}`,
          url: '/governance/boc-work-guidelines',
        },
        {
          id: 1,
          title: `${this.$t('navbar.governance.cocBod')}`,
          url: '/governance/bod-work-guidelines',
        },
        {
          id: 2,
          title: `${this.$t('navbar.governance.auditCommitte')}`,
          url: '/governance/audit-committee',
        },
        {
          id: 3,
          title: `${this.$t('navbar.governance.nominationAndremuneration')}`,
          url: '/governance/nomination-remuneration-committee',
        },
        {
          id: 4,
          title: `${this.$t('navbar.governance.corporateSecretary')}`,
          url: '/governance/corporate-secretary',
        },
        {
          id: 5,
          title: `${this.$t('navbar.governance.internalAudit')}`,
          url: '/governance/internal-audit',
        },
        {
          id: 6,
          title: `${this.$t('navbar.governance.codeOfConduct')}`,
          url: '/governance/code-of-conduct',
        },
        {
          id: 7,
          title: `${this.$t('navbar.governance.riskManagement')}`,
          url: '/governance/risk-management',
        },
        {
          id: 8,
          title: `${this.$t('navbar.governance.whistleblowingSystem')}`,
          url: '/governance/whistleblowing-system',
        },
        {
          id: 9,
          title: `${this.$t('navbar.governance.antiCorruption')}`,
          url: '/governance/anti-corruption',
        },
      ]
    },
    itemsSustainability() {
      return [
        {
          id: 0,
          title: `${this.$t('navbar.sustainability.environment')}`,
          url: '/sustainability/environment',
        },
        {
          id: 1,
          title: `${this.$t('navbar.sustainability.socialResponsibility')}`,
          url: '/sustainability/social-responsibility',
        },
      ]
    },
    itemsServices() {
      return [
        {
          id: 0,
          title: `${this.$t('navbar.business.fleet')}`,
          url: '/services/fleet',
        },
      ]
    },
    itemsInvestors() {
      return [
        {
          id: 0,
          title: `${this.$t('navbar.investor.procpectus')}`,
          url: '/investors/prospectus',
        },
        {
          id: 1,
          title: `${this.$t('navbar.investor.annualReport')}`,
          url: '/investors/annual-report',
        },
        {
          id: 2,
          title: `${this.$t('navbar.investor.financialReport')}`,
          url: '/investors/financial-report',
        },
        {
          id: 3,
          title: `${this.$t('navbar.investor.rups')}`,
          url: '/investors/rups',
        },
        {
          id: 4,
          title: `${this.$t('navbar.investor.performance')}`,
          url: '/investors/performance',
        },
        {
          id: 5,
          title: `${this.$t('navbar.investor.stockInformation')}`,
          url: '/investors/stock-information',
        },
        {
          id: 6,
          title: `${this.$t('navbar.investor.bondInformation')}`,
          url: '/investors/bond-information',
        },
        {
          id: 7,
          title: `${this.$t('navbar.investor.dividenInformation')}`,
          url: '/investors/dividen-information',
        },
        {
          id: 8,
          title: `${this.$t('navbar.investor.corporateAction')}`,
          url: '/investors/corporate-action',
        },
        {
          id: 9,
          title: `${this.$t('navbar.investor.presentation')}`,
          url: '/investors/investor-presentation',
        },
        {
          id: 10,
          title: `${this.$t('navbar.investor.idxAnnouncement')}`,
          url: '/investors/idx-announcement',
        },
        {
          id: 11,
          title: `${this.$t('navbar.investor.institutionAndCapitalMarket')}`,
          url: '/company/institution-and-capital-market-supporting-professionals',
        },
      ]
    },
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll, { passive: true })
  },
  async created() {
    await this.fetchData()
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY
    },
    async fetchData() {
      this.isLoading = true
      const res = await this.$services.webSetting.getItemKey({
        itemKey: this.itemKey,
      })
      if (res.success) {
        this.headerLogoData = res.data
      } else {
        this.headerLogoData = []
      }
      this.isLoading = false
    },
  },
}
