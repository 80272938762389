import { baseRes } from './response'
const url = process.env.API_BASE_URL
export default (axios) => ({
  getAuditComitteeReport(
    payload = {
      startYear: '',
      endYear: '',
    },
    errCallback = null
  ) {
    return baseRes(
      axios.get(
        `${url}/report-audit-committee/v1?startYear=${payload.startYear}&endYear=${payload.endYear}`
      ),
      errCallback
    )
  },
  getNominationRemunerationCommitteeReport(
    payload = {
      startYear: '',
      endYear: '',
    },
    errCallback = null
  ) {
    return baseRes(
      axios.get(
        `${url}/report-nomination-remuneration-committee/v1?startYear=${payload.startYear}&endYear=${payload.endYear}`
      ),
      errCallback
    )
  },
  getInternalAuditReport(
    payload = {
      startYear: '',
      endYear: '',
    },
    errCallback = null
  ) {
    return baseRes(
      axios.get(
        `${url}/report-internal-audit/v1?startYear=${payload.startYear}&endYear=${payload.endYear}`
      ),
      errCallback
    )
  },
  getCorporateSecretaryReport(
    payload = {
      startYear: '',
      endYear: '',
    },
    errCallback = null
  ) {
    return baseRes(
      axios.get(
        `${url}/report-corporate-secretary/v1?startYear=${payload.startYear}&endYear=${payload.endYear}`
      ),
      errCallback
    )
  },
  getAuditComitteMember(
    payload = {
      page: 1,
      size: 10,
    },
    errCallback = null
  ) {
    return baseRes(
      axios.get(
        `${url}/governance-audit-committee-member/v1?page=${payload.page}&size=${payload.size}`
      ),
      errCallback
    )
  },
  getInternalAuditMember(
    payload = {
      page: 1,
      size: 10,
    },
    errCallback = null
  ) {
    return baseRes(
      axios.get(
        `${url}/governance-internal-audit-member/v1?page=${payload.page}&size=${payload.size}`
      ),
      errCallback
    )
  },
  getCorporateSecretaryMember(
    payload = {
      page: 1,
      size: 10,
    },
    errCallback = null
  ) {
    return baseRes(
      axios.get(
        `${url}/governance-corporate-secretary-member/v1?page=${payload.page}&size=${payload.size}`
      ),
      errCallback
    )
  },
  getNominationRemunerationCommitteeMember(
    payload = {
      page: 1,
      size: 10,
    },
    errCallback = null
  ) {
    return baseRes(
      axios.get(
        `${url}/governance-nomination-remuneration-committee-member/v1?page=${payload.page}&size=${payload.size}`
      ),
      errCallback
    )
  },
  getBoardOfCommissionersCharter(
    payload = {
      page: 1,
      size: 10,
    },
    errCallback = null
  ) {
    return baseRes(
      axios.get(
        `${url}/governance-boc-charter/v1?page=${payload.page}&size=${payload.size}`
      ),
      errCallback
    )
  },
  getBoardOfDirectorsCharter(
    payload = {
      page: 1,
      size: 10,
    },
    errCallback = null
  ) {
    return baseRes(
      axios.get(
        `${url}/governance-bod-charter/v1?page=${payload.page}&size=${payload.size}`
      ),
      errCallback
    )
  },
  getSinglePage(
    payload = {
      page: 1,
      size: 10,
      type: '',
    },
    errCallback = null
  ) {
    return baseRes(
      axios.get(
        `${url}/governance-single-page/${payload.type}/v1?page=${payload.page}&size=${payload.size}`
      ),
      errCallback
    )
  },
})
