import { baseRes } from './response'
const url = `${process.env.API_BASE_URL}`
export default (axios) => ({
  getBoc(
    payload = {
      page: 1,
      size: 10,
    },
    errCallback = null
  ) {
    return baseRes(
      axios.get(
        `${url}/company-boc-member/v1?page=${payload.page}&size=${payload.size}`
      ),
      errCallback
    )
  },
  getBod(
    payload = {
      page: 1,
      size: 10,
    },
    errCallback = null
  ) {
    return baseRes(
      axios.get(
        `${url}/company-bod-member/v1?page=${payload.page}&size=${payload.size}`
      ),
      errCallback
    )
  },
  getMilestones(
    payload = {
      page: 1,
      size: 100,
    },
    errCallback = null
  ) {
    return baseRes(
      axios.get(
        `${url}/milestones/v1?page=${payload.page}&size=${payload.size}`
      ),
      errCallback
    )
  },
  getCertifications(
    payload = {
      page: 1,
      size: 100,
    },
    errCallback = null
  ) {
    return baseRes(
      axios.get(
        `${url}/certifications/v1?page=${payload.page}&size=${payload.size}`
      ),
      errCallback
    )
  },
  getSinglePage(
    payload = {
      page: 1,
      size: 100,
      type: '',
    },
    errCallback = null
  ) {
    return baseRes(
      axios.get(
        `${url}/company-structure/${payload.type}/v1?page=${payload.page}&size=${payload.size}`
      ),
      errCallback
    )
  },
})
