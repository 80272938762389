import { render, staticRenderFns } from "./Navbar.vue?vue&type=template&id=3f45d3db&scoped=true&"
import script from "./Navbar.vue?vue&type=script&lang=js&"
export * from "./Navbar.vue?vue&type=script&lang=js&"
import style0 from "./Navbar.vue?vue&type=style&index=0&id=3f45d3db&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f45d3db",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DownArrowBlue: require('/usr/src/app/src/components/icons/DownArrowBlue.vue').default,UpArrowBlue: require('/usr/src/app/src/components/icons/UpArrowBlue.vue').default,DownArrowWhite: require('/usr/src/app/src/components/icons/DownArrowWhite.vue').default,UpArrowWhite: require('/usr/src/app/src/components/icons/UpArrowWhite.vue').default})
