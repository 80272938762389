export const helper = ({ redirect, app, route, store }) => ({
  getCopy(en, id) {
    if (app.i18n.locale === 'en') {
      return en
    } else {
      return id
    }
  },
  getExcerpt(en, id) {
    let excerpt = ''
    if (app.i18n.locale === 'en') {
      if (en.length > 167) {
        excerpt = en.slice(0, 167) + '...'
      } else {
        excerpt = en
      }
      return excerpt
    } else {
      if (id.length > 167) {
        excerpt = id.slice(0, 167) + '...'
      } else {
        excerpt = id
      }
      return excerpt
    }
  },
  download(data) {
    const link = document.createElement('a')
    link.href = data
    link.download = `name`
    link.click()
    URL.revokeObjectURL(link.href)
  },
})

export default ({ redirect, app, route, store }, inject) => {
  inject('helper', helper({ redirect, app, route, store }))
}
